// Import required libraries
import axios from "axios";

// Import store
import { store } from "../../redux/storeInitializer";

// Import action creators
import { logout, ssoLogout } from "../../redux/actions";

// Import utils
import {
  getSigviewUserType,
  getEmbedInfo,
  isUserNonSigviewOaa,
  getBaseUrlEndpoints,
} from "../../utils/utils";
import { config } from "../../config/config";

// Import static data
import errorMessagesNonSigview from "../../../assets/data/errorMessagesNonSigview.json";
import errorMessagesSigview from "../../../assets/data/errorMessagesSigview.json";
// import { include } from "underscore";

// Making a mapping for error messages
var errorMessagesMapping = {};
const sigviewUserType = getSigviewUserType();
if (sigviewUserType === "sigview") {
  errorMessagesSigview.forEach((errorObj) => {
    errorMessagesMapping[errorObj.statusCode] = errorObj.message;
  });
} else {
  errorMessagesNonSigview.forEach((errorObj) => {
    errorMessagesMapping[errorObj.statusCode] = errorObj.message;
  });
}

// A list of statusText which should not be overridden by UI
const keepStatusTextList = config.hardCoded.keepStatusTextList;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let authHeaders = {};
    const globalReduxState = store.getState();
    const sigviewUserType = getSigviewUserType();

    if (sigviewUserType === "sigview") {
      // For querryCancellation Only for Analyze
      let sessionIdFlag = window.location.href.includes("/analyze");
      if (sessionIdFlag) {
        authHeaders = {
          "X-Auth-Token": globalReduxState.user.auth.data["X-Auth-Token"],
          session_id: globalReduxState.user?.reqMetadata?.session_id,
          useremail: localStorage.getItem("sigview_email"),
        };
      } else {
        authHeaders = {
          "X-Auth-Token": globalReduxState.user.auth.data["X-Auth-Token"],
          useremail: localStorage.getItem("sigview_email"),
        };
      }
    } else {
      authHeaders = {
        "X-Auth-Token": globalReduxState.user.auth.data["X-Auth-Token"],
        // ! COMMENTED DUE TO "400 Request Header Or Cookie Too Large" THIS WARNING
        // token: globalReduxState.user.auth.data["openx-token"],
        usertype: globalReduxState.user.auth.data["openx-userType"],
        useremail: globalReduxState.user.auth.data["openx-email"],
        // ...(globalReduxState.user?.metadata?.data?.email
        //   ? { "openx-email": globalReduxState.user?.metadata?.data?.email }
        //   : {}),
        ...(globalReduxState.user?.reqMetadata?.view
          ? { view: globalReduxState.user?.reqMetadata?.view }
          : {}),
        ...(globalReduxState.user?.reqMetadata?.organization
          ? { organization: globalReduxState.user?.reqMetadata?.organization }
          : {}),
      };
    }

    const updConfig = {
      ...config,
      headers: { ...config.headers, ...authHeaders },
    };
    return updConfig;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    const data = response.data;
    const status = data?.status?.statusCode;
    const statusText = data?.status?.statusMessage;

    // ! NEW IMPLEMENTATION
    const errorText = errorMessagesMapping[status] || statusText;
    let errorFlag = false;

    // * OLD IMPLEMENTATION
    // ! This hard coding is because of poor and inconsistent API design
    // const keepStatusTextFlag = keepStatusTextList.includes(statusText);
    // const result = data?.result;
    // const statusText2 = result?.response || "";
    // const statusText3 = result?.data?.response || "";
    // const keepStatusTextFlag2 = keepStatusTextList.includes(statusText2);
    // const keepStatusTextFlag3 = statusText2.includes("Upload failed");
    // const keepStatusTextFlag4 = statusText3.includes("Upload failed");
    // let errorText = keepStatusTextFlag
    //   ? statusText
    //   : keepStatusTextFlag2
    //   ? statusText2
    //   : keepStatusTextFlag3
    //   ? statusText2
    //   : keepStatusTextFlag4
    //   ? statusText3
    //   : errorMessagesMapping[status];
    // let errorFlag = false;
    // console.log("errorText", errorText);

    //check if local storage has openxData and satellizer token; if not, logout
    //Need to check the organization and based on that we need to change
    const globalReduxState = store.getState();
    const openxUserData = JSON.parse(localStorage.getItem("openxUserData"));
    const { xAuthToken } = getEmbedInfo(globalReduxState.user);
    let sigviewUserType = getSigviewUserType();
    if (isUserNonSigviewOaa(globalReduxState.user))
      sigviewUserType = "nonSigviewOaa";
    const authErrorUrlMapping = config.hardCoded.authErrorUrlMapping;
    if (sigviewUserType === "sigview") {
      if (!xAuthToken) {
        store.dispatch(logout());
      }
    } else {
      if (!openxUserData || !xAuthToken) {
        if (
          JSON.parse(localStorage?.getItem("openxUserData"))?.[0].userType ===
          "OAA"
        ) {
          store.dispatch(ssoLogout());
        } else {
          store.dispatch(logout());
        }
      }
    }

    switch (status) {
      case "200":
        errorFlag = false;
        break;
      case "204":
        errorFlag = true;
        break;
      case "304":
        errorFlag = true;
        break;
      case "305":
        errorFlag = true;
        break;
      case "306":
        errorFlag = true;
        break;
      //no logout from Prathyusha
      case "400":
        errorFlag = true;
        break;
      //no logout from Prathyusha
      case "401":
        store.dispatch(logout());
        errorFlag = true;
        break;
      //yes logout from Prathyusha
      case "403":
        store.dispatch(logout());
        errorFlag = true;
        break;
      //no logout from Prathyusha (generally for not found and not for unauth)
      case "404":
        store.dispatch(logout());
        errorFlag = true;
        break;
      case "408":
        store.dispatch(logout());
        errorFlag = true;
        break;
      case "409": //Conflict; Same name, etc.
        errorFlag = true;
        break;
      case "422": //Conflict; Same name, etc.
        errorFlag = true;
        break;
      case "428": //Special message.
        errorFlag = true;
        break;
      //yes logout
      case "424":
        store.dispatch(logout());
        errorFlag = true;
        break;
      //no logout from Prathyusha
      case "500":
        errorFlag = true;
        break;
      case "520":
        errorFlag = true;
        break;
      case "521":
        errorFlag = true;
        break;
      case "601":
        errorFlag = true;
        break;
      case "621":
        errorFlag = true;
        break;
      default:
        errorFlag = false;
    }
    // * OLD IMPLEMENTATION
    // return errorFlag
    //   ? Promise.reject({ error: errorText || statusText })
    //   : response.data;

    // ! NEW IMPLEMENTATION
    return errorFlag ? Promise.reject({ error: errorText }) : response.data;
  },
  function (error) {
    //If the query got cancelled, return cancel error
    if (axios.isCancel(error))
      return Promise.reject({ error: config.hardCoded.queryCancelled });

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const response = error.response;
    const status = response.status;
    var statusText = response.statusText;
    const globalReduxState = store.getState();
    if (isUserNonSigviewOaa(globalReduxState.user))
      sigviewUserType = "nonSigviewOaa";
    switch (status) {
      case 400:
        statusText = "Bad request";
        break;
      case 401:
        store.dispatch(logout());
        break;
      case 403:
        store.dispatch(logout());
        break;
      case 404:
        statusText = config.hardCoded.uiErrorMessage;
        break;
      case 424:
        store.dispatch(logout());
        break;
      case 500:
        break;
      default:
        console.log(statusText);
    }
    return Promise.reject({ error: statusText });
  }
);

export function getUser(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrlOld}${config.apiEndpoints.getUser}`;

  //Making variables from props
  const { url = defaultUrl, headers = {} } = props;
  const promise = axios.get(url, { headers });
  return promise;
}

export function getUserAccessList(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getUserAccessList}`;
  const defaultPayload = {
    organization: globalReduxUser?.reqMetadata?.organization,
    view: globalReduxUser?.reqMetadata?.view,
  };

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getUserEmbedded(props) {
  let { url, headers } = props;
  const promise = axios.get(url, { headers });
  return promise;
}

export function getMetricList(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const sigviewUserType = getSigviewUserType();
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getMetricList}`;
  let defaultPayload = {};
  //Difference in Sigview & Non-Sigview
  //BACKLOG BACKEND
  if (sigviewUserType === "sigview") {
    defaultPayload = {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    };
  } else {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      userPreferredLanguage:
        globalReduxUser?.reqMetadata?.userPreferredLanguage,
    };
  }

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getDimensionList(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const sigviewUserType = getSigviewUserType();
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getDimensionList}`;
  let defaultPayload = {};
  //Difference in Sigview & Non-Sigview
  //BACKLOG BACKEND
  if (sigviewUserType === "sigview") {
    defaultPayload = {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    };
  } else {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      userPreferredLanguage:
        globalReduxUser?.reqMetadata?.userPreferredLanguage,
    };
  }

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getCustomMetricList(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const sigviewUserType = getSigviewUserType();
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getCustomMetricList}`;
  let defaultPayload = {};
  //Difference in Sigview & Non-Sigview
  //BACKLOG BACKEND
  if (sigviewUserType === "sigview") {
    defaultPayload = {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    };
  } else {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      userPreferredLanguage:
        globalReduxUser?.reqMetadata?.userPreferredLanguage,
    };
  }

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getDateRange(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getDateRange}`;
  const defaultPayload = {
    organization: globalReduxUser?.reqMetadata?.organization,
    view: globalReduxUser?.reqMetadata?.view,
  };

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getDatastories(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints["sample"]}/${globalReduxUser?.reqMetadata?.organization}/${globalReduxUser?.reqMetadata?.view}`;

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
}

export function getDatastoryById(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints["datastorySampleById"]}/123`;

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
}

export function updateUserView(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateView}`;
  const defaultPayload = {};

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getData(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.dataUrl}${config.apiEndpoints.getData}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function getComparisonData(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.dataUrl}${config.apiEndpoints.getComparisonData}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function getDimensionValues(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getDimensionValues}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const responseData = axios.post(url, payload, { headers, cancelToken });
  return responseData;
}

export function getFileUploadLocation(props) {
  //Making default values from store and config
  const sigviewUserType = getSigviewUserType();
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  let defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getFileUploadLocationSigview}`;
  //Difference in Sigview & Non-Sigview
  //BACKLOG BACKEND
  if (sigviewUserType === "sigview") {
    defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getFileUploadLocationSigview}`;
  } else {
    defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getFileUploadLocationNonSigview}`;
  }

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken } = props;
  const responseData = axios.get(url, { headers, cancelToken });
  return responseData;
}

export function uploadToGCPBucket(props) {
  const { url = "", payload = {}, headers = {}, cancelToken } = props;
  const responseData = axios.post(url, payload, {
    headers,
    cancelToken,
  });
  return responseData;
}

export function uploadToAWSBucket(props) {
  const { url = "", payload = {}, headers = {}, cancelToken } = props;
  const responseData = axios.put(url, payload, {
    headers,
    cancelToken,
  });
  return responseData;
}

export function uploadFileMetadataToServer(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.uploadFileMetadataToServer}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const responseData = axios.post(url, payload, {
    headers,
    cancelToken,
  });
  return responseData;
}

//REPORT MANAGER API LIST
export function getAllScheduledReports(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getAllScheduledReports}`;
  var defaultPayload;
  const sigviewUserType = getSigviewUserType();
  if (sigviewUserType === "sigview") {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      userId: globalReduxUser?.reqMetadata?.email,
    };
  } else {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      email: globalReduxUser?.reqMetadata?.email,
    };
  }

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getAllSavedReports(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getAllSavedReports}`;
  const defaultPayload = {
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    email: globalReduxUser?.reqMetadata?.email,
  };

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getReportsDownloadHistory(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getReportsDownloadHistory}`;
  const defaultPayload = {
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    email: globalReduxUser?.reqMetadata?.email,
  };

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function addScheduledReport(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.addScheduledReport}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function updateScheduledReport(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateScheduledReport}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function deleteScheduledReport(props) {
  const { url = "", headers = {} } = props;
  const promise = axios.delete(url, { headers });
  return promise;
}

export function addSavedReport(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.addSavedReport}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function updateSavedReport(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateSavedReport}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function deleteSavedReport(props) {
  const { url = "", headers = {} } = props;
  const promise = axios.delete(url, { headers });
  return promise;
}

export function getReportLinkOverEmail(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getReportLinkOverEmail}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getReportOverEmail(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getReportOverEmail}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function downloadReport(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.instantDownload}`;

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function downloadReportFile(props) {
  //Making default values from store and config
  const defaultUrl = "";

  //Making variables from props
  const { url = defaultUrl, payload = {}, headers = {} } = props;
  const promise = axios.get(url, { responseType: "blob" }, { headers });
  return promise;
}

//PLOT TAB
export function getChartObjectList(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getChartObjectList}`;
  const defaultPayload = {
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    emailId: globalReduxUser?.reqMetadata?.email,
  };

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

// const samplePayload = {
//   id: "Datastory_ChartId_1575367191893",
//   title: "DimIDRenamed",
//   orgViewReq: { organization: "OpenX", view: "CPR" },
// };

export function renameChart(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.renameChart}`;
  const defaultPayload = {
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
  };

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function saveChart(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.saveChart}`;
  const defaultPayload = {
    _id: "",
    emailId: globalReduxUser?.reqMetadata?.email,
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    chartObject: {},
  };

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function updateChart(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateChart}`;
  const defaultPayload = {
    _id: "",
    emailId: globalReduxUser?.reqMetadata?.email,
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    chartObject: {},
  };

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function deleteChart(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.deleteChart}`;

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
}

export function saveDs(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.saveDs}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function updateDs(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateDs}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
}

export function deleteDs(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.deleteDs}`;

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken } = props;
  const promise = axios.delete(url, { headers, cancelToken });
  return promise;
}

export function renameDs(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.renameDs}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function dsNameCheck(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.dsNameCheck}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function updateAutoSaveStatus(props = {}) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateAutoSaveStatus}`;
  const defaultPayload = {
    autoSaveStatus: true,
    email: globalReduxUser?.reqMetadata?.email,
  };

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function saveBookmark(props = {}) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.saveBookmark}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

// ADMIN API FUNCTIONS
// API call for read List all the views
export const readAdminByOrg = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminViews}/${orgName}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};
export const fetchBucketList = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  console.log("globalReduxState", globalReduxState);
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization || "openx";
  // console.log("readAdminByOrg", globalReduxUser);
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/fetchBucketList/${orgName}`;
  //const defaultUrl = "https://devint-auth.sigmoid.io/api/v1/admin/views/OpenX";

  const headers = {
    "X-Auth-Token": globalReduxState.user.auth.data["X-Auth-Token"],
    "openx-token": globalReduxState.user.auth.data["openx-token"],
    "openx-userType": globalReduxState.user.auth.data["openx-userType"],
    "openx-email": globalReduxState.user.auth.data["openx-email"],
  };

  const { url = defaultUrl, cancelToken } = props;

  const promise = axios.get(url, { headers });
  return promise;
};
export const fetchBucket = (props = {}) => {
  const { id = "" } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  console.log("globalReduxState", globalReduxState);
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization || "openx";
  // console.log("readAdminByOrg", globalReduxUser);
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/fetchBucket/${orgName}/${id}`;
  //const defaultUrl = "https://devint-auth.sigmoid.io/api/v1/admin/views/OpenX";

  const headers = {
    "X-Auth-Token": globalReduxState.user.auth.data["X-Auth-Token"],
    "openx-token": globalReduxState.user.auth.data["openx-token"],
    "openx-userType": globalReduxState.user.auth.data["openx-userType"],
    "openx-email": globalReduxState.user.auth.data["openx-email"],
  };

  const { url = defaultUrl, cancelToken } = props;

  const promise = axios.get(url, { headers });
  return promise;
};

// API call for bucket test connection
export const testBucketConnection = (props = {}) => {
  const { type = "" } = props;

  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  //const defaultUrl = `https://devint-auth.sigmoid.io/api/v1/admin/group`;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/testConnection`;
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for save/update bucket
export const updateBucketDetails = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  //const defaultUrl = `https://devint-auth.sigmoid.io/api/v1/admin/group`;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/bucket`;
  console.log("defaultUrl", defaultUrl);
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
};

export const saveBucketDetails = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  //const defaultUrl = `https://devint-auth.sigmoid.io/api/v1/admin/group`;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/bucket`;
  console.log("defaultUrl", defaultUrl);
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for read List all the viewsDetails
export const readAdminAwsByOrg = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminViews}/${orgName}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};
export const readAdminByOrgVeiwDetails = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.adminAttributeSourceInfo}${orgName}`;
  const headers = {};

  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};

// API call for read List all the views
export const readAttributeFormula = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminFormula}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};

export const readAttributeAwsFormula = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminFormula}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};

// API call for read List all the groups
export const readGroupsByOrg = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminGroups}/${orgName}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};

export const readAttributeGroupsByOrgViewId = (props = {}) => {
  const { view, id } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminAttributeGroups}/${orgName}/${view}/${id}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};

export const readUsersByOrg = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminUsers}/${orgName}`;

  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};

export const readUsersAwsByOrg = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminUsers}`;
  const headers = {};
  const { url = defaultUrl, payload = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

export const readViewGroupListAwsByOrg = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminViewGroupList}`;

  const headers = {};
  const { url = defaultUrl, payload = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

export const readGroupById = (props = {}) => {
  const { id } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminGroup}/${orgName}/${id}`;

  const headers = {};
  const { url = defaultUrl, payload = {}, cancelToken } = props;
  const promise = axios.get(url, payload, { headers, cancelToken });
  return promise;
};

// API call for create attribute
export const createAttribute = (props = {}) => {
  const { type = "" } = props;

  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminCreateAttribute}/${type}`;
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for create attribute
export const createAttributeAws = (props = {}) => {
  const { type = "" } = props;

  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/${type}${config.apiEndpoints.sigviewAdminCreateAttribute}`;
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for update attribute
export const updateAttribute = (props = {}) => {
  const { type = "" } = props;

  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminUpdateAttribute}/${type}`;
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
};

export const updateAttributeAws = (props = {}) => {
  const { type = "" } = props;

  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/${type}${config.apiEndpoints.sigviewAdminUpdateAttribute}`;
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
};

// API call for update group in attribute
export const updateAttributeGroup = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminUpdateAttributeGroup}`;
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;

  // Making variables from props
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
};

// API call for create group
export const createGroup = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminCreateGroup}`;

  // Making variables from props
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for update group
export const updateGroup = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminUpdateGroup}`;
  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
};

// API call for name check
export const nameCheckGroup = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminNameCheckGroup}`;

  // Making variables from props
  const headers = {};
  const { url = defaultUrl, payload = props } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
};

// API call for name check
export const nameCheckAttribute = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminNameCheckAttribute}`;

  // Making variables from props
  const headers = {};
  const { url = defaultUrl, payload = props } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
};

// API call for name check
export const nameCheckAttributeAws = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminAttributeNameCheck}`;

  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;

  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for read List all the user by id
export const readUserById = (props = {}) => {
  const { email } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const orgName = globalReduxUser?.reqMetadata?.organization;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminReadUser}/${orgName}/${email}`;
  const headers = {};
  const { url = defaultUrl, cancelToken } = props;
  const promise = axios.get(url, { headers, cancelToken });
  return promise;
};
// API call for read List all the user by id
export const readUserAwsById = (props = {}) => {
  const { email } = props;
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminReadUser}`;
  const headers = {};
  const { url = defaultUrl, payload = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for delete user
export const deleteUserAws = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminDeleteUser}`;
  const headers = {};
  const { url = defaultUrl, payload = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for update user
export const updateUser = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminUpdateUser}`;
  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
  const promise = axios.put(url, payload, { headers, cancelToken });
  return promise;
};

// API call for create user
export const createUserAws = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminCreateUser}`;

  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;

  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for update user
export const updateUserAws = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminUpdateUser}`;

  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;

  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for create group
export const createUser = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminCreateUser}`;

  // Making variables from props
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for email check
export const emailCheckUser = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminCheckUserEmail}`;

  // Making variables from props
  const headers = {};
  const { url = defaultUrl, payload = props } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
};

// API call for email check adminAws
export const emailCheckUserAws = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminCheckUserEmail}`;

  // Making variables from props
  const headers = {};
  const { url = defaultUrl, payload = props } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
};

export const sendEmailVerificationLink = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.sigviewAdminEmailVerificationLink}`;

  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;

  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for checking available group for a particular view
export const readAvailableGroupData = (props = {}) => {
  const { org = "", view = "" } = props;
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.nonSigviewAdminReadAllGroupsWithView}/${org}/${view}`;
  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
  const promise = axios.get(url, payload, { headers, cancelToken });
  return promise;
};

export function chartNameCheck(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.chartNameCheck}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

// API call for POLL OPENX SSO EVERY 50 MINUTES TO CHECK IF SECURITY FILTERS OF A USER ARE CHANGED
export const refreshToken = (props = {}) => {
  const { org = "", view = "" } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.refreshToken}`;

  //Making variables from props
  const { url = defaultUrl, headers = {} } = props;
  const promise = axios.get(url, { headers });
  return promise;
};

// Banner API
export function getFeatureInfo(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const sigviewUserType = getSigviewUserType();
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getFeatureInfo}`;
  let defaultPayload = {};
  //Difference in Sigview & Non-Sigview
  //BACKLOG BACKEND
  if (sigviewUserType === "sigview") {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      feature: "bannerNotification",
    };
  } else {
    defaultPayload = {
      orgViewReq: {
        organization: globalReduxUser?.reqMetadata?.organization,
        view: globalReduxUser?.reqMetadata?.view,
      },
      feature: "bannerNotification",
    };
  }

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;
  const promise = axios.post(url, payload, { headers });
  return promise;
}

export function getWorksheetById(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.worksheetById}`;
  const defaultPayload = {
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    email: globalReduxUser?.reqMetadata?.email,
    workbookId: "",
    worksheetId: "",
  };

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function sendResetPasswordLink(props = {}) {
  //Making default values from store and config
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultPayload = {};
  const defaultUrl = `${apiEndpoints.baseUrl}${config.apiEndpoints.sendResetPasswordLink}`;

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload } = props;

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const promise = fetch(url, options);
  return promise;
}

export function sigviewSignIn(props) {
  const defaultPayload = {};
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrlOld}${config.apiEndpoints.sigviewSignIn}`;

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, cancelToken } = props;

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      frameWork: "react", // This is for the new react deployed we can remove this after 1 month of react deployment
    },
    body: JSON.stringify(payload),
  };

  const promise = fetch(defaultUrl, options);
  return promise;
}

export function sigviewSignInSso(props) {
  const defaultPayload = {};
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrlOld}${config.apiEndpoints.sigviewSignInSSO}`;

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, headers = {} } = props;

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(payload),
  };

  const promise = fetch(url, options);
  return promise;
}

export function getAllWorkbooks(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getAllWorkbooks}`;
  // * TESTING URL (for empty worksheetList in workbook)
  // const defaultUrl =
  //   "https://run.mocky.io/v3/d56a9c43-1d90-42e3-bf55-69044c933488";

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken, payload = {} } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function dimDownloadRequest(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.instantDownload}`;

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken, payload = {} } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function metricDownloadRequest(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.instantDownload}`;

  //Making variables from props
  const { url = defaultUrl, headers = {}, cancelToken, payload = {} } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

// * IsTokenValid API call
// API call for checking a Token valid or Not
export const isTokenValidAPIcall = (props = {}) => {
  // Making variables from props
  const { resetTokenId = "" } = props;

  //Making default values from store and config
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrl}${config.apiEndpoints.isTokenValid}/${resetTokenId}`;
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const promise = fetch(defaultUrl, options);
  return promise;
};

// * Reset Password API Call
// API call for sending a request for Reset password
export const resetPasswordAPIcall = (props = {}) => {
  // Making default values from store and config
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrl}${config.apiEndpoints.resetPassword}`;
  const defaultPayload = {};
  // Making variables from props
  const { url = defaultUrl, payload = defaultPayload } = props;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const promise = fetch(url, options);
  return promise;
};

// * Change Password API Call
// API call for sending a request for change password
export const changePasswordAPIcall = (props = {}) => {
  // Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.changePassword}`;
  // Making variables from props
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// * Alert Manager API lists
// API call for read alerts
export const readAlerts = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.readAlerts}`;

  const defaultPayload = {
    userId: globalReduxUser?.reqMetadata?.email,
    orgViewReq: {
      organization: globalReduxUser?.reqMetadata?.organization,
      view: globalReduxUser?.reqMetadata?.view,
    },
    email: globalReduxUser?.reqMetadata?.email,
  };

  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// API call for create alerts
export const createAlert = (props = {}) => {
  // Making default values from store and config

  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.addAlert}`;

  // Making variables from props
  const { url = defaultUrl, payload = {}, headers = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// edit alerts
export const updateAlert = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateAlert}`;
  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// Delete patterns
export const deleteAlert = (props = {}) => {
  const { url = "", headers = {}, alert_id } = props;

  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.deleteAlert}?patternId=${alert_id}`;

  const promise = axios.delete(defaultUrl, { headers });
  return promise;
};

export function checkWorksheetName(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.checkWorksheetName}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function deleteWorksheet(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.deleteWorksheet}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function renameWorksheet(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.renameWorksheet}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function saveWs(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.addWorksheet}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function updateWs(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateWorksheet}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function updateWorksheetStatus(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateWorksheetStatus}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function getBookmark(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getBookmark}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function getGroupDefaultWorksheet(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.getGroupDefaultWorksheet}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function updateVersion(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;

  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}${config.apiEndpoints.updateVersion}`;
  const defaultPayload = {};

  //Making variables from props
  const {
    url = defaultUrl,
    payload = defaultPayload,
    headers = {},
    cancelToken,
  } = props;
  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
}

export function get(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrlOld}${config.apiEndpoints.get}`;

  //Making variables from props
  const { url = defaultUrl, headers = {} } = props;
  const promise = axios.get(url, { headers });
  return promise;
}

export function signOut(props) {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrlOld}${config.apiEndpoints.signOut}`;

  //Making variables from props
  const { url = defaultUrl, headers = {} } = props;
  const promise = axios.get(url, { headers });
  return promise;
}

export function oAuthSignIn(props) {
  const defaultPayload = {};
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrlOld}${config.apiEndpoints.oAuthSignIn}`;

  //Making variables from props
  const { url = defaultUrl, payload = defaultPayload, cancelToken } = props;

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  };

  const promise = fetch(defaultUrl, options);
  return promise;
}

// Update Preferred Language
export const savePreferredLanguage = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/savePreferredLanguage`;

  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;

  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};
// Update Preferred Language
export const usersWiseReport = (props = {}) => {
  //Making default values from store and config
  const globalReduxState = store.getState();
  const globalReduxUser = globalReduxState.user;
  const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/admin/usersWiseReport`;

  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;

  const promise = axios.post(url, payload, { headers, cancelToken });
  return promise;
};

// export const getAuth2 = (props = {}) => {
//   const globalReduxState = store.getState();
//   const globalReduxUser = globalReduxState.user;
//   const defaultUrl = `${globalReduxUser.apiEndpoints.baseUrl}/oauth2`;
//   const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
//   const promise = fetch(url, payload, { headers, cancelToken });
//   return promise;
// };

export const getToken = (props = {}) => {
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrl}/getUserToken`;
  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
  const promise = fetch(url, {
    method: "GET",
    credentials: "include",
  });
  return promise;
};

export const ssoConfig = (props = {}) => {
  const apiEndpoints = getBaseUrlEndpoints();
  const defaultUrl = `${apiEndpoints.baseUrl}/sso-config`;
  const { url = defaultUrl, payload, headers = {}, cancelToken } = props;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const promise = fetch(url, options);
  return promise;
};
